<template>
  <Loading v-if="loading && loading[$route.params.slug] !== false" />
  <div v-if="currentUser && currentUser.public_id">
    <div>

      <div class="md:my-4 mx-2">
        <div
          :class="{
            'text-right relative w-12 float-right': true,
            'cprofile-menu': currentRouteName == 'CProfileWithSlug' && user.public_id == currentUser.public_id,
            'mr-3': currentRouteName != 'CProfileWithSlug'
          }"

          @click="show = !show"
        >
          <!-- <button class="" v-if="user && user.public_id && user.public_id == currentUser.public_id">
            <img
              src="../../../assets/image/more.png"
              alt=""
              class="h-2"
            />
          </button> -->
          <div v-if="currentRouteName != 'CProfileWithSlug' " :class="{'border-b-2  mx-6 mt-3 mb-5':true,
          'border-black' : activeTab != 'AddToProfile',
          'border-gray-300' : activeTab == 'AddToProfile' 
          }">
          </div>
          <div class="mt-2">
            <ul
              class="dropdown-menu absolute right-0 top-8 text-white bg-gray-400 rounded-lg text-left z-50"
              v-if="show"
            >
              <li v-if="user.type == 'artist'">
                <router-link
                  class="rounded-t py-2 px-4 block whitespace-nowrap lg:text-xl md:text-2xl text-xl"
                  :to="{name:'PurchaseHistory'}"
                >
                   View Purchase History
                </router-link>
              </li>
              <li v-if="user.type != 'artist'">
                <router-link
                  class="rounded-t py-2 px-4 block whitespace-nowrap lg:text-xl md:text-2xl text-xl"
                  :to="{name:'VotingHistory'}"
                >
                  View Voting History
                </router-link>
              </li>
              <li v-if="user.type == 'artist'">
                <a
                  class="py-2 px-4 block whitespace-nowrap lg:text-xl md:text-2xl text-xl"
                  id="AddMusic"
                  @click="userSelectedTab = 'AddMedia'"
                >
                  Add Video
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            <AddToProfile
              :currentProfileUserId="currentUser.public_id"
              :isVoted="isVoted"
              @changePlays="updatePlays"
              :user_name = "currentUser.member_name ? currentUser.member_name : '' "
              v-if="activeTab == 'AddToProfile'"
            />
          </div>
          <div>
            <AddMedia @mediaAdded="mediaAdded" v-if="activeTab == 'AddMedia'" />
          </div>
          <!-- <div v-if="paymentHistory">
            <PaymentHistory />
          </div> -->
          <!-- <div v-if="subscribePlan">
            <MakePayment></MakePayment>
          </div> -->
          <div v-if="activeTab == 'VotingHistory'">
            <VotingHistory></VotingHistory>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../api/http";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import AddToProfile from "./AddToProfile";
// import PaymentHistory from "./PaymentHistory";
import AddMedia from "./AddMedia";
// import MakePayment from "./MakePayment";
import VotingHistory from "./VotingHistory";
import { mapGetters } from 'vuex';
import Loading from "../../Shared/Loading";

export default {
  name: "Profile",
  components: {
    AddToProfile,
    AddMedia,
    // PaymentHistory,
    // MakePayment,
    VotingHistory,
    Loading,
  },
  data() {
    return {
      media_base_url: process.env.VUE_APP_MEDIA_BASE_URL,
      input: true,
      show: false,
      showYoutubeInput: false,
      showBlastInput: false,
      showInstagramInput: false,
      showSoundcloudInput: false,
      token: null,
      // currentUser: null,
      profileUpdate: {
        public_id: null,
        member_name: null,
        type: null,
        state_public_id: null,
        blast: null,
        instagram: null,
        soundcloud: null,
        youtube: null,
      },
      myProfile: false,
      currentroute: "",
      // isVoted: false,
      profileImage: null,
      previewImageUrl: null,
      showPreviewImage: false,
      toast: useToast(),
      userSelectedTab: false,
      // loading: true,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({ user: 'User/user' }),
    currentRouteName() {
        return this.$route.name;
    },
    currentUser() {
      return this.$store.getters["Cprofile/currentUser"][this.$route.params.slug];
    },
    isVoted() {
      return this.$store.getters["Cprofile/isVoted"][this.$route.params.slug];
    },
    loading() {
      return this.$store.getters["Cprofile/loading"];
    },
    activeTab(){

      if(!this.userSelectedTab && this.user.type == 'artist' && this.user.public_id == this.currentUser.public_id){
        if(this.user.artist_songs_count > 0){
          return 'AddToProfile';
        }else{
          return 'AddMedia';
        }
      }else{
        if(!this.userSelectedTab){
          if(this.user.type == 'user'){
             return 'AddToProfile';
          }
          if(this.user.public_id != this.currentUser.public_id){
            return 'AddToProfile';
          }
        }
        return this.userSelectedTab;
      }
    },
  },
  methods: {
    init() {
      let url =
        this.user && this.user.public_id
          ? "get-artist-info/"
          : "guest-get-artist-info/";

      API.get(url + this.$route.params.slug).then((response) => {
        // this.currentUser = response.artist;
        // this.currentUser.plays = response.plays;
        // this.currentUser.votes = response.votes;
        // this.currentUser.isTopArtist = response.is_top_artist;
        // this.currentUser.trophy = response.trophy;
        // this.isVoted = response.is_voted == "true" ? true : false;
        // this.loading = false;
        response.is_voted = response.is_voted == "true" ? true : false
        this.$store.commit("Cprofile/SET_ARTIST", response);
        this.$store.commit("Cprofile/SET_LOADING", {
          status: false,
          slug: this.$route.params.slug
        });
      });
    },
    // toggleMenu() {
    //   this.show = !this.show;
    // },
    // updateBlastId() {
    //   this.showBlastInput = !this.showBlastInput;
    //   this.updateData();
    // },
    // updateInstagramId() {
    //   this.showInstagramInput = !this.showInstagramInput;
    //   this.updateData();
    // },
    // updateSoundcloudId() {
    //   this.showSoundcloudInput = !this.showSoundcloudInput;
    //   this.updateData();
    // },
    // updateYoutubeId() {
    //   this.showYoutubeInput = !this.showYoutubeInput;
    //   this.updateData();
    // },
    // updateData() {
    //   API.post("profile-update", {
    //     member_name: this.currentProfileData.member_name,
    //     type: this.currentProfileData.type,
    //     state_public_id: this.currentProfileData.state.public_id,
    //     blast: this.currentProfileData.blast,
    //     instagram: this.currentProfileData.instagram,
    //     soundcloud: this.currentProfileData.soundcloud,
    //     youtube: this.currentProfileData.youtube,
    //   }).then((response) => {
    //     // console.log(response);
    //     var updateUser = this.user;
    //     updateUser.blast = response.user.blast;
    //     updateUser.instagram = response.user.instagram;
    //     updateUser.soundcloud = response.user.soundcloud;
    //     updateUser.youtube = response.user.youtube;
    //     this.$store.commit("User/SET_USER", updateUser);
    //     this.toast.success(response.message);
    //   });
    // },
    vote() {
      // console.log(this.user.public_id);
      API.post("vote", {
        artist_public_id: this.currentUser.public_id,
      }).then((response) => {
        // console.log(response)
        if (response.message == "Vote successfully submitted.") {
          this.currentUser.votes = response.votes;
          this.toast.success(response.message);
          this.isVoted = true;
        }
      });
    },
    addAttachment() {
      this.$refs.profileImage.click();
      this.showPreviewImage = true;
    },
    makePreviewImage() {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.previewImageUrl = e.target.result;
      };
      reader.readAsDataURL(this.$refs.profileImage.files[0]);
    },
    cancelPreviewImage() {
      this.showPreviewImage = false;
      this.previewImageUrl = "";
    },
    changeProfileImage() {
      this.profileImage = this.$refs.profileImage.files[0];


      var formData = new FormData();

      if (this.profileImage) {
        formData.append("image", this.profileImage);

        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        API.post("profile-image-upload", formData, config).then((response) => {
          if (response.message == "Profile image added successfully!") {
            this.currentProfileData.image = response.image;
            this.showPreviewImage = false;
            this.previewImageUrl = "";
          }
        });
      }
    },
    updatePlays(e) {
      this.currentProfileData.plays = e;
    },
    mediaAdded() {
      this.userSelectedTab = false
    },
    // changeProfileData(e) {
    //   console.log(this.currentProfileData);
    //   this.currentUser.member_name = e.member_name;
    //   this.currentUser.blast = e.blast;
    //   this.currentUser.youtube = e.youtube;
    //   this.currentUser.instagram = e.instagram;
    //   this.currentUser.soundcloud = e.soundcloud;
    // },
    goBack(){
      this.$router.go(-1)
    }
  },
};
</script>
